import React, { useCallback, useEffect, useState } from 'react';
import { Link, graphql, useStaticQuery, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { animated as a, useSprings } from 'react-spring';
import useMeasure from 'react-use-measure';
import polyfill from '@juggle/resize-observer';

import Layout from '../components/layout';
import SEO from '../components/helpers/seo';
import useInterval from '../hooks/useInterval';
import useTranslation from '../hooks/useTranslation';
import mq from '../styles/mq';
import ServicesPlus from '../svg/logo/services-plus.svg';
import { SWITCH_LANGUAGE } from '../actions/types';

import { services as allService } from '../components/services/services';

const Home = () => {
  const dispatch = useDispatch();
  const { locale } = useSelector(state => state.i18n);
  const { hero } = useStaticQuery(query);
  const t = useTranslation();
  const [current, setCurrent] = useState(0);
  const [services, setServices] = useState(() =>
    allService[locale].map(service => service.title)
  );
  const [ref, { width }] = useMeasure({ polyfill });

  const [carousel, set] = useSprings(services.length, i => ({
    transform: `translate3d(${current * -width}px, 0px, 0px)`,
  }));

  useEffect(() => {
    set({
      transform: `translate3d(${current * -width}px, 0px, 0px)`,
    });
  });

  useInterval(() => {
    if (current === services.length - 1) {
      setCurrent(0);
    } else {
      setCurrent(current => current + 1);
    }
  }, 3000);

  const onChange = async e => {
    const locale = e.target.value;

    dispatch({
      type: SWITCH_LANGUAGE,
      locale,
    });
    await navigate(`/${locale}`);
  };

  const renderServices = useCallback(
    () => (
      <ul
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
        `}
      >
        {carousel.map((props, key) => {
          const service = services[key];

          return (
            <a.li
              style={props}
              key={key}
              css={css`
                flex: 0 0 100%;
                max-width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                font-weight: 600;
              `}
            >
              {service}
            </a.li>
          );
        })}
      </ul>
    ),
    [carousel, services]
  );

  return (
    <Layout
      nav={false}
      pages={{
        en: `/en`,
        fr: `/fr`,
      }}
    >
      <SEO title="Home" />
      <div className="Lang">
        <select onChange={onChange} defaultValue={locale}>
          <option value="en">EN</option>
          <option value="fr">FR</option>
        </select>
      </div>
      <section
        css={css`
          height: 100vh;
          position: relative;
          overflow: hidden;
        `}
      >
        <div
          css={css`
            position: fixed;
            top: 25px;
            left: 25px;
            z-index: 2;

            svg {
              height: 75px;
              width: auto;
            }
          `}
        >
          <ServicesPlus />
        </div>
        <Img
          fluid={hero.childImageSharp.fluid}
          css={css`
            position: initial !important;
            height: 110%;
            max-width: 768px;
            margin: 0 auto;

            ${mq(`md`)} {
              max-width: initial;
              margin: initial;
            }
          `}
        />
        <div
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(3, 56, 109, 0.4);
            }
          `}
        >
          <div
            css={css`
              position: relative;
              z-index: 1;
              text-align: center;
            `}
          >
            <h1
              css={css`
                color: white;
                font-size: 40px;
                text-transform: uppercase;
                margin-bottom: 50px;

                @media screen and (min-width: 768px) {
                  font-size: 80px;
                }
              `}
            >
              Services Plus
            </h1>
            <Link
              to={t(`nav.links`)[1].path}
              css={css`
                background-color: white;
                padding: 0.5rem 2rem;
                color: hsl(210, 95%, 22%);
                border-radius: 10px;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 0.8rem;

                @media screen and (min-width: 768px) {
                  font-size: 1rem;
                  padding: 0.75rem 2rem;
                }
              `}
            >
              {t(`home.discover`)}
            </Link>
          </div>
        </div>
        <div
          ref={ref}
          css={css`
            background-color: rgba(3, 56, 109, 0.5);
            height: 80px;
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            color: white;
          `}
        >
          {renderServices(services)}
        </div>
      </section>
    </Layout>
  );
};

const query = graphql`
  {
    hero: file(name: { eq: "1" }, relativeDirectory: { eq: "home" }) {
      childImageSharp {
        fluid(
          maxWidth: 1440
          maxHeight: 1000
          cropFocus: CENTER
          quality: 100
          fit: COVER
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Home;
